import { combineReducers } from "@reduxjs/toolkit";
import { reducer as modalsReducer } from "../slices/modals";
import { reducer as authReducer } from "../slices/auth";
import { reducer as switchReducer } from "../slices/switch";

const combinedReducer = combineReducers({
  modals: modalsReducer,
  auth: authReducer,
  switch: switchReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
