import { Suspense, Fragment, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";
import Layout from "./layouts/MainLayout";
import LoadingScreen from "./components/LoadingScreen";

export const RenderRoutes = ({ routes = [] }) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? (
                    <RenderRoutes routes={route.routes} />
                  ) : (
                    <Element />
                  )}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    element: lazy(() => import("./pages/Auth/Login")),
  },

  {
    path: "/*",
    guard: AuthGuard,
    // layout: Layout,
    routes: [
      {
        // exact: true,
        path: "/",
        element: lazy(() => import("./pages/Home")),
      },
      {
        // exact: true,
        path: "/choose-class",
        element: lazy(() => import("./pages/Classes")),
      },
      {
        path: "/register",
        element: lazy(() => import("./pages/Auth/Register")),
      },

      {
        path: "*",
        element: lazy(() => import("./pages/Auth/NotFound")),
      },
    ],
  },
];

export default routes;
