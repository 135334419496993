import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useSelector } from "react-redux";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useSelector((state) => state.switch);
  http: if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
